import { lazy } from "react";
export { default as SiteHeader } from "./SiteHeader";

export const Announcement = lazy(() => import("./Announcement"));
export const Body = lazy(() => import("./Body"));
export const Content = lazy(() => import("./Content"));
export const CTA = lazy(() => import("./CTA"));
export const Description = lazy(() => import("./Description"));
export const Detail = lazy(() => import("./Detail"));
export const DynamicHtml = lazy(() => import("./DynamicHtml"));
export const DynamicText = lazy(() => import("./DynamicText"));
export const Form = lazy(() => import("./Form"));
export const GoogleJobsSchema = lazy(() => import("./GoogleJobsSchema"));
export const Image = lazy(() => import("./Image"));
export const JobCard = lazy(() => import("./JobCard"));
export const LatestBlogCard = lazy(() => import("./LatestBlogCard"));
export const NoSSR = lazy(() => import("./NoSSR"));
export const Phone = lazy(() => import("./Phone"));
export const RelatedJobCard = lazy(() => import("./RelatedJobCard"));
export const SiteFooter = lazy(() => import("./SiteFooter"));
export const SocialShare = lazy(() => import("./SocialShare"));
export const Tag = lazy(() => import("./Tag"));
export const Time = lazy(() => import("./Time"));
export const Title = lazy(() => import("./Title"));
export const Video = lazy(() => import("./Video"));
export const VideoModal = lazy(() => import("./VideoModal"));
