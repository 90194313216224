import "@/scss/styles.scss";
import { getGlobal } from "@/functions/getGlobal";
import { Suspense } from "react";
import { SiteFooter, SiteHeader } from "@/ui";
import SourceFlowHead from "@sourceflow-uk/sourceflow-head";
import SourceFlowTracker from "@sourceflow-uk/sourceflow-tracker";
import metaObject from "../../.sourceflow/metadata.json";
import Announcement from "@/ui/Announcement";
// import CookieConsent from "@/components/Shared/CookieConsent";
import Script from "next/script";

export default function App({ Component, pageProps }) {
  const global = getGlobal();
  const gtag = global["_theme.google.gtag"];

  return (
    <>
    <Suspense>
      <Announcement />
      <SourceFlowHead metaObject={metaObject}>
        {pageProps?.meta?.title && <title>{pageProps.meta.title}</title>}
      </SourceFlowHead>
      <SiteHeader className={global["_theme.header.classes"]} />
      <main className="flex-grow-1">
        <Component {...pageProps} />
      </main>
      <SiteFooter className={global["_theme.footer.classes"]} />
    </Suspense>
      {/* <CookieConsent> */}
      <Script id="Cookiebot" src='https://consent.cookiebot.com/uc.js' data-cbid="08d0febd-47e1-4f57-b23b-f9d7f5121703" data-blockingmode="auto" />

      <script
            dangerouslySetInnerHTML={{
              __html: `
              window.hfAccountId = "d34d01ff-61a9-49d7-932d-bd234f0c6808";
              window.hfDomain = "https://api.herefish.com";
                  (function() {
                      var hf = document.createElement('script'); hf.type = 'text/javascript'; hf.async = true;
                      hf.src = window.hfDomain + '/scripts/hf.js';
                      var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(hf, s);
                  })();
            `,
            }}
          />

        {gtag && (<Script strategy="lazyOnload" src={`https://www.googletagmanager.com/gtag/js?id=${gtag}`}  />)}
        {gtag && (
          <Script id='gtm_script' strategy="lazyOnload" dangerouslySetInnerHTML={{ __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', '${gtag}');
              `}}
          />
        )}
        <SourceFlowTracker cookieExpiryTime={3000} />
      {/* </CookieConsent> */}
    </>
  );
}
