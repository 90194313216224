import PropTypes from "prop-types";
import { Container } from "react-bootstrap";
import clsx from "classnames";
import classes from "./styles.module.scss";
import { getGlobal } from "@/functions/getGlobal";
import Body from "../Body";
import ArrowRight2 from "@/assets/ArrowRight2.svg";
import { useEffect, useState } from "react";
import Link from 'next/link';

export default function Announcement({ className }) {
  const global = getGlobal();
  const announcement = global["announcement.text"];
  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };
    
    handleScroll();

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  if(!announcement) {
    return <></>;
  } else {
    return (
      <Link href={global["announcement.link"]} passHref>
        <div className={clsx(className, classes.announcement, (scrollY > 0 ? classes.scrolled : ""))}>
            <Body body={global["announcement.text"]} className={classes.body} />
            <div className={classes.link}>
                <ArrowRight2 className={classes.icon} />
            </div>
        </div>
      </Link>
    );
  }
}

Announcement.defaultProps = {
  className: "",
};

Announcement.propTypes = {
  className: PropTypes.string,
};
