import React from "react";
import PropTypes from "prop-types";
import { DynamicHtml } from "@/ui";
import clsx from "classnames";
import classes from "./styles.module.scss";

/**
 *
 * @param className
 * @param body
 * @param tag
 * @returns {React.DetailedReactHTMLElement<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>|JSX.Element|null}
 * @constructor
 */
export default function Body({ className, body, tag }) {
  if (!body) {
    return null;
  }

  if (typeof body === "string") {
    return React.createElement(tag, {
      className: clsx(classes.body, className),
      dangerouslySetInnerHTML: { __html: body },
    });
  }

  return (
    <DynamicHtml className={clsx(classes.body, className)} path={body.path} tag={tag}>
      <span dangerouslySetInnerHTML={{ __html: body.placeholder }} />
    </DynamicHtml>
  );
}

Body.defaultProps = {
  className: "",
  body: null,
  tag: "div",
};

Body.propTypes = {
  className: PropTypes.string,
  body: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      path: PropTypes.string,
      placeholder: PropTypes.string,
    }),
  ]),
  tag: PropTypes.oneOf(["div", "span", "detail", "blockquote"]),
};
